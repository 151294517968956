import { EvolutionGames } from "./evolutiongames.data";
import { EzugiGames } from "./ezugigames.data";
import { slotsData } from "./slots.data";
import { WordCasinoGames } from "./wcogames.data";

export const CasinoProviders = {
  evolution: {
    code: "",
    casino: "ezugi",
    href: "/casino/evolution",
    provider: "EVO_LOBBY",
    homeUrl: "",
    games: EvolutionGames,
  },
  ezugi: {
    href: "/casino/ezugi",
    code: "",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    games: EzugiGames,
  },
  worldcasino: {
    href: "/casino/worldcasino",
    code: "",
    casino: "wco",
    provider: "",
    homeUrl: "",
    games: WordCasinoGames,
  },
  pragmaticplay: {
    href: "/casino/pragmaticplay",
    code: "",
    casino: "wco",
    provider: "PP",
    homeUrl: "",
    games: slotsData,
  },
  onetouch: {
    href: "/casino/onetouch",
    code: "",
    casino: "wco",
    provider: "OT",
    homeUrl: "",
    games: {},
  },
  onlyplay: {
    href: "/casino/onlyplay",
    code: "",
    casino: "wco",
    provider: "GT",
    homeUrl: "",
    games: {},
  },
  qtech: {
    href: "/casino/qtech",
    code: "",
    casino: "qtech",
    provider: "QT",
    homeUrl: "",
    games: {},
  },

  supernowa: {
    href: "/casino/supernowa",
    code: "",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    games: WordCasinoGames,
  },
  vivo: {
    href: "/casino/vivo",
    code: "vivo",
    casino: "vivo",
    provider: "",
    homeUrl: "",
  },

  xpg: {
    href: "/casino/xpg",
    code: "",
    casino: "wco",
    provider: "XPG",
    homeUrl: "",
    games: {},
  },

  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      plinko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
};
