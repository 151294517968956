import React from "react";
import HeartIcon from "../../assets/svg/HeartIcon";

export default function CasinoCard({ data }) {
  return (
    <a href={data.redirectUrl || "#"} className="col-6 col-lg-3 mb-4">
      <div className="shadow rounded position-relative">
        <img src={data?.imgUrl} alt={data?.cover?.alt || ""} />
        <span className="like-btn">
          <HeartIcon />
        </span>
      </div>
    </a>
  );
}
