import React from "react";
import LeftBarSports from "../../../components/LeftBarSports";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";
import CasinoCard from "../../../components/CasinoCard";
import { casinoTabs } from "../../../../lib/data";

const Spribe = () => {
  const navigate = useNavigate();

  return (
    <>
      {" "}
      <HeaderAfterLogin />{" "}
      <main className="main mt-4 SpribeSection GamesSection">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="container">
          <div className="pagesHeading">
            <h4>Spribe</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div>
            <ul>
              {Array.isArray(Object.values(casinoTabs?.spribe.slide1)) &&
                Object.values(casinoTabs?.spribe.slide1)
                  .slice(0, 7)
                  .map((game, idx) => (
                    <CasinoCard key={"casinogame" + idx} data={game} />
                  ))}
            </ul>
          </div>
        </div>
      </main>
    </>
  );
};

export default Spribe;
