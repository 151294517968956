export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  populartab: {
    slide1: {
      AndarBaharVivo: {
        redirectUrl: "/casino/vivo-andar-bahar",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/popular/AndarBaharVivo.jpg",
        alt: "",
        name: "Andar Bahar Vivo",
        providerName: "Vivo",
      },
      TeenPattiVivo: {
        redirectUrl: "/casino/vivo-teen-patti",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/popular/TeenPattiVivo.jpg",
        alt: "",
        name: "Teen Patti Vivo",
        providerName: "Vivo",
      },
    },
    name: "Popular",
  },
  roulettetab: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
      // AutoRoulettePargmatic: {
      //   redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
      //   code: "vs20stickysymbol",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
      //   alt: "",
      //   name: "Auto Roulette Pragmatic",
      //   providerName: "Pragmatic Play",
      // },
      // Roulette1Azure: {
      //   redirectUrl: "/casino/ezugi/Roulette1Azure",
      //   code: "227",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Roulette1Azure.jpg",
      //   alt: "",
      //   name: "Roulette Azure",
      //   providerName: "Pragmatic Play",
      // },
      // RouletteLobby: {
      //   redirectUrl: "/casino/ezugi/RouletteLobby",
      //   code: "102",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/roulletewheel.jpg",
      //   alt: "",
      //   name: "Roulette Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    // slide3: {
    //   AutoRoulettePargmatic: {
    //     redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
    //     code: "225",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
    //     alt: "",
    //     name: "Auto Roulette Pragmatic",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette1Azure: {
    //     redirectUrl: "/casino/ezugi/Roulette1Azure",
    //     code: "227",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette1Azure.jpg",
    //     alt: "",
    //     name: "Roulette Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteLobby: {
    //     redirectUrl: "/casino/ezugi/RouletteLobby",
    //     code: "102",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulletewheel.jpg",
    //     alt: "",
    //     name: "Roulette Lobby",
    //     providerName: "Pragmatic Play",
    //   },
    //   PragmaticTurkishRoulette: {
    //     redirectUrl: "/casino/ezugi/PragmaticTurkishRoulette",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/pragturkish.jpg",
    //     alt: "",
    //     name: "Turkish Roulette ",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulettegerman: {
    //     redirectUrl: "/casino/ezugi/roulettegerman",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulettegerman.jpg",
    //     alt: "",
    //     name: "Roulette German",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteRussian: {
    //     redirectUrl: "/casino/ezugi/RouletteRussian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette-Russian.jpg",
    //     alt: "",
    //     name: "Roulette Russian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette3Macao: {
    //     redirectUrl: "/casino/ezugi/Roulette3Macao",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette3Macao.jpg",
    //     alt: "",
    //     name: "Roulette Macao",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette9TheClub: {
    //     redirectUrl: "/casino/ezugi/Roulette9TheClub",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette9TheClub.jpg",
    //     alt: "",
    //     name: "Roulette The Club",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulette7italian: {
    //     redirectUrl: "/casino/ezugi/roulette7italian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulette7italian.jpg",
    //     alt: "",
    //     name: "Roulette 7 Italian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette10Ruby: {
    //     redirectUrl: "/casino/ezugi/Roulette10Ruby",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette10Ruby.jpg",
    //     alt: "",
    //     name: "Roulette 10 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Roulette",
  },
  baccarattab: {
    slide1: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide2: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    // slide3: {
    //   NoCommSpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/NoCommSpeedBaccarat1",
    //     code: "436",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/NoCommSpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "No Commission Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   SpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/SpeedBaccarat1",
    //     code: "566",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/SpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "Speed Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   // speedbaccarat5: {
    //   //   redirectUrl: "/casino/ezugi/speedbaccarat5",
    //   //   code: "570",
    //   //   casino: "ezugi",
    //   //   provider: "",
    //   //   homeUrl: pageUrlGenerator("/Casino"),
    //   //   imgUrl: "./images/roulette/speedbaccarat5.jpg",
    //   //   alt: "",
    //   //   name: "Speed Baccarat 5",
    //   //   providerName: "Pragmatic Play",
    //   // },
    // },
    name: "Baccarat",
  },
  Blackjacktab: {
    slide1: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },
      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      // TurkceBlackjack: {
      //   redirectUrl: "/casino/ezugi/TurkceBlackjack",
      //   code: "501",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
      //   alt: "",
      //   name: "Turkce Blackjack 3",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      // FreebetBlackjack: {
      //   redirectUrl: "/casino/ezugi/FreebetBlackjack",
      //   code: "1000066",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
      //   alt: "",
      //   name: "Freebet Blackjack",
      //   providerName: "Evolution",
      // },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack1: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack1",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide2: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      // ClubBlackjack: {
      //   redirectUrl: "/casino/ezugi/ClubBlackjack",
      //   code: "502",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Club-Blackjack.jpg",
      //   alt: "",
      //   name: "Club Blackjack",
      //   providerName: "Ezugi",
      // },
      // Rumbablackjacks2: {
      //   redirectUrl: "/casino/ezugi/Rumbablackjacks2",
      //   code: "412",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 2",
      //   providerName: "Ezugi",
      // },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      // AllStarsBlackjackGold1: {
      //   redirectUrl: "/casino/ezugi/AllStarsBlackjackGold1",
      //   code: "504",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
      //   alt: "",
      //   name: "All Stars Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },

    // slide3: {
    //   Blackjack17Azure: {
    //     redirectUrl: "/casino/ezugi/Blackjack17Azure",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack17Azure.jpg",
    //     alt: "",
    //     name: "Blackjack 17 Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   GoldBlackjack: {
    //     redirectUrl: "/casino/ezugi/GoldBlackjack",
    //     code: "1",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack02.jpg",
    //     alt: "",
    //     name: "Blackjack Gold 5",
    //     providerName: "Ezugi",
    //   },
    //   Blackjack39Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack39Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack39Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 39 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack42Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack42Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack42Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 42 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   UnlimitedBlackjack: {
    //     redirectUrl: "/casino/ezugi/UnlimitedBlackjack",
    //     code: "51",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Unlimited-Blackjack.jpg",
    //     alt: "",
    //     name: "Unlimited Blackjack",
    //     providerName: "Ezugi",
    //   },

    //   BlackjackVIPO: {
    //     redirectUrl: "/casino/ezugi/BlackjackVIPO",
    //     code: "1000058",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack-VIP-O.jpg",
    //     alt: "",
    //     name: "Blackjack VIP O",
    //     providerName: "Evolution",
    //   },
    //   Blackjack11: {
    //     redirectUrl: "/casino/ezugi/Blackjack11",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack11.jpg",
    //     alt: "",
    //     name: "Blackjack 11",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack14: {
    //     redirectUrl: "/casino/ezugi/Blackjack14",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack14.jpg",
    //     alt: "",
    //     name: "Blackjack 14",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack16: {
    //     redirectUrl: "/casino/ezugi/Blackjack16",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack16.jpg",
    //     alt: "",
    //     name: "Blackjack 16",
    //     providerName: "Pragmatic Play",
    //   },
    //   OneBlackjack: {
    //     redirectUrl: "/casino/ezugi/OneBlackjack",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/OneBlackjack.jpg",
    //     alt: "",
    //     name: "One Blackjack",
    //     providerName: "Pragmatic Play",
    //   },
    //   ONEBlackjack2: {
    //     redirectUrl: "/casino/ezugi/ONEBlackjack2",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/ONEBlackjack2.jpg",
    //     alt: "",
    //     name: "One Blackjack 2",
    //     providerName: "Pragmatic Play",
    //   },
    //   SpeedBlackjack3Ruby: {
    //     redirectUrl: "/casino/ezugi/SpeedBlackjack3Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/SpeedBlackjack3Ruby.jpg",
    //     alt: "",
    //     name: "Speed Blackjack 3 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Blackjack",
  },
  pokertab: {
    slide1: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti1: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti1",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    name: "Poker",
  },
  spribe: {
    slide1: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/aviator.gif",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },

    name: "Spribe",
  },
  Supernowa: {
    slide1: {
      // playerTP: {
      //   redirectUrl: "/casino/supernowa/playerTP",
      //   code: "D3TP",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/3PlayerTeenPatti.jpg",
      //   alt: "",
      //   name: "3 Player TeenPatti",
      //   providerName: "Supernowa",
      // },
      Cards32SN: {
        redirectUrl: "/casino/supernowa/Cards32SN",
        code: "C32",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/32Card.jpg",
        alt: "",
        name: "32 Cards",
        providerName: "Supernowa",
      },
      AkbarRomeoWalter: {
        redirectUrl: "/casino/supernowa/AkbarRomeoWalter",
        code: "ARW",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/AkbarRomeoWalter.jpg",
        alt: "",
        name: "Akbar Romeo Walter",
        providerName: "Supernowa",
      },
      AndarBaharSN: {
        redirectUrl: "/casino/supernowa/AndarBaharSN",
        code: "ABC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/AndarBahar.jpg",
        alt: "",
        name: "Andar Bahar",
        providerName: "Supernowa",
      },
      BaccaratSN: {
        redirectUrl: "/casino/supernowa/BaccaratSN",
        code: "BAC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Baccarat.jpg",
        alt: "",
        name: "Baccarat",
        providerName: "Supernowa",
      },
      // CasinoQueen: {
      //   redirectUrl: "/casino/supernowa/CasinoQueen",
      //   code: "CQ",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/CasinoQueen.jpg",
      //   alt: "",
      //   name: "Casino Queen",
      //   providerName: "Supernowa",
      // },
      ClassicAndarBaharSN: {
        redirectUrl: "/casino/supernowa/ClassicAndarBaharSN",
        code: "AB",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/ClassicAndarBahar.jpg",
        alt: "",
        name: "Classic Andar Bahar",
        providerName: "Supernowa",
      },
      DragonTigerSN: {
        redirectUrl: "/casino/supernowa/DragonTigerSN",
        code: "DT7M",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/DragonTiger.jpg",
        alt: "",
        name: "Dragon Tiger",
        providerName: "Supernowa",
      },
      GoaAndarBaharSN: {
        redirectUrl: "/casino/supernowa/GoaAndarBaharSN",
        code: "AB2",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/GoasAndarBahar.jpg",
        alt: "",
        name: "Goa's Andar Bahar",
        providerName: "Supernowa",
      },
      // KingRace: {
      //   redirectUrl: "/casino/supernowa/KingRace",
      //   code: "CR",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/KingRace.jpg",
      //   alt: "",
      //   name: "King Race",
      //   providerName: "Supernowa",
      // },
      Lucky7SN: {
        redirectUrl: "/casino/supernowa/Lucky7SN",
        code: "UD7",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Lucky7.jpg",
        alt: "",
        name: "Lucky 7",
        providerName: "Supernowa",
      },
      // PokerSN: {
      //   redirectUrl: "/casino/supernowa/PokerSN",
      //   code: "PK",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/Poker.jpg",
      //   alt: "",
      //   name: "Poker",
      //   providerName: "Supernowa",
      // },
    },
    slide2: {
      RouletteSN: {
        redirectUrl: "/casino/supernowa/RouletteSN",
        code: "RT",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Roulette.jpg",
        alt: "",
        name: "Roulette",
        providerName: "Supernowa",
      },
      TeenPattiSN: {
        redirectUrl: "/casino/supernowa/TeenPattiSN",
        code: "TP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Teen-Patti.jpg",
        alt: "",
        name: "Teen Patti",
        providerName: "Supernowa",
      },
      TeenPatti2020SN: {
        redirectUrl: "/casino/supernowa/TeenPatti2020SN",
        code: "TP20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/TeenPatti2020.jpg",
        alt: "",
        name: "Teen Patti 2020",
        providerName: "Supernowa",
      },
      TeenPattiFaceOffSN: {
        redirectUrl: "/casino/supernowa/TeenPattiFaceOffSN",
        code: "TPFO",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/TeenPattiFaceOff.jpg",
        alt: "",
        name: "Teen Patti Face Off",
        providerName: "Supernowa",
      },
      // WorliMatka: {
      //   redirectUrl: "/casino/supernowa/WorliMatka",
      //   code: "WM",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/WorliMatka.jpg",
      //   alt: "",
      //   name: "Worli Matka",
      //   providerName: "Supernowa",
      // },
      // RNG3CardsJudgement: {
      //   redirectUrl: "/casino/supernowa/RNG3CardsJudgement",
      //   code: "V3CJ",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNG3CardsJudgement.jpg",
      //   alt: "",
      //   name: "RNG 3 Cards Judgement",
      //   providerName: "Supernowa",
      // },
      RNG3PlayerTeenPatti: {
        redirectUrl: "/casino/supernowa/RNG3PlayerTeenPatti",
        code: "V3TP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG3PlayerTeenPatti.jpg",
        alt: "",
        name: "RNG 3 Player Teen Patti",
        providerName: "Supernowa",
      },
      RNG32Cards: {
        redirectUrl: "/casino/supernowa/RNG32Cards",
        code: "VC32",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG32Cards.jpg",
        alt: "",
        name: "RNG 32 Cards",
        providerName: "Supernowa",
      },
      RNGAkbarRomeoWalter: {
        redirectUrl: "/casino/supernowa/RNGAkbarRomeoWalter",
        code: "VARW",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGAkbarRomeoWalter.jpg",
        alt: "",
        name: "RNG Akbar Romeo Walter",
        providerName: "Supernowa",
      },
      // RNGAndarBahar2020: {
      //   redirectUrl: "/casino/supernowa/RNGAndarBahar2020",
      //   code: "VAB20",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGAndarBahar2020.jpg",
      //   alt: "",
      //   name: "RNG Andar Bahar 2020",
      //   providerName: "Supernowa",
      // },
      RNGBaccarat: {
        redirectUrl: "/casino/supernowa/RNGBaccarat",
        code: "VBAC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGBaccarat.jpg",
        alt: "",
        name: "RNG Baccarat",
        providerName: "Supernowa",
      },
      // RNGBaccarat2020: {
      //   redirectUrl: "/casino/supernowa/RNGBaccarat2020",
      //   code: "VBAC20",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGBaccarat2020.jpg",
      //   alt: "",
      //   name: "RNG Baccarat 2020",
      //   providerName: "Supernowa",
      // },
    },
    slide3: {
      RNGCasinoQueen: {
        redirectUrl: "/casino/supernowa/RNGCasinoQueen",
        code: "RCQ",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
        alt: "",
        name: "RNG Casino Queen",
        providerName: "Supernowa",
      },
      RNGCasinoQueen2020: {
        redirectUrl: "/casino/supernowa/RNGCasinoQueen2020",
        code: "RCQ20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
        alt: "",
        name: "RNG Casino Queen 2020",
        providerName: "Supernowa",
      },
      RNGCricket2020: {
        redirectUrl: "/casino/supernowa/RNGCricket2020",
        code: "RCKT20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCricket2020.jpg",
        alt: "",
        name: "RNG Cricket 2020",
        providerName: "Supernowa",
      },
      RNGDragonTiger: {
        redirectUrl: "/casino/supernowa/RNGDragonTiger",
        code: "VDT",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
        alt: "",
        name: "RNG Dragon Tiger",
        providerName: "Supernowa",
      },
      RNGDragonTiger2020: {
        redirectUrl: "/casino/supernowa/RNGDragonTiger2020",
        code: "VDT20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
        alt: "",
        name: "RNG Dragon Tiger 2020",
        providerName: "Supernowa",
      },
      RNGJoker: {
        redirectUrl: "/casino/supernowa/RNGJoker",
        code: "VJKR",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGJoker.jpg",
        alt: "",
        name: "RNG Joker",
        providerName: "Supernowa",
      },
      // RNGKabutarFuddi: {
      //   redirectUrl: "/casino/supernowa/RNGKabutarFuddi",
      //   code: "VKF",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGKabutarFuddi.jpg",
      //   alt: "",
      //   name: "RNG Kabutar Fuddi",
      //   providerName: "Supernowa",
      // },
      RNGKingRace: {
        redirectUrl: "/casino/supernowa/RNGKingRace",
        code: "VCR",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGKingRace.jpg",
        alt: "",
        name: "RNG King Race",
        providerName: "Supernowa",
      },
      RNGLucky7: {
        redirectUrl: "/casino/supernowa/RNGLucky7",
        code: "VUD7",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGLucky7.jpg",
        alt: "",
        name: "RNG Lucky 7",
        providerName: "Supernowa",
      },
      // RNGPoker: {
      //   redirectUrl: "/casino/supernowa/RNGPoker",
      //   code: "VPK",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGPoker.jpg",
      //   alt: "",
      //   name: "RNG Poker",
      //   providerName: "Supernowa",
      // },
      // RNGPoker2020: {
      //   redirectUrl: "/casino/supernowa/RNGPoker2020",
      //   code: "VPK20",
      //   casino: "wco",
      //   provider: "SN",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/supernowa/RNGPoker2020.jpg",
      //   alt: "",
      //   name: "RNG Poker 2020",
      //   providerName: "Supernowa",
      // },
      RNGTeenPatti: {
        redirectUrl: "/casino/supernowa/RNGTeenPatti",
        code: "VTP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
        alt: "",
        name: "RNG Teen Patti",
        providerName: "Supernowa",
      },
      RNGTeenPatti2020: {
        redirectUrl: "/casino/supernowa/RNGTeenPatti2020",
        code: "VTP20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
        alt: "",
        name: "RNG Teen Patti 2020",
        providerName: "Supernowa",
      },
      RNGWorliMatka: {
        redirectUrl: "/casino/supernowa/RNGWorliMatka",
        code: "VWM",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
        alt: "",
        name: "RNG Worli Matka",
        providerName: "Supernowa",
      },
    },

    name: "Supernowa",
  },
  Vivo: {
    slide1: {
      CoffeeWild: {
        redirectUrl: "/casino/vivo-dragon-tiger",
        imgUrl: "./images/Vivo/DragonTiger.jpg",
        name: "Dragon Tiger",
        providerName: "Vivo",
      },
      EuropeanAutoRoulette: {
        redirectUrl: "/casino/vivo-european-auto-roulette",
        imgUrl: "./images/Vivo/EuropeanAutoRoulette.jpg",
        name: "European Auto Roulette",
        providerName: "Vivo",
      },
      FrenchRoulette: {
        redirectUrl: "/casino/vivo-french-roulette",
        imgUrl: "./images/Vivo/FrenchRoulette.jpg",
        name: "French Roulette",
        providerName: "Vivo",
      },
      GalaxyBaccarat1: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-1",
        imgUrl: "./images/Vivo/GalaxyBaccarat1.jpg",
        name: "Galaxy Baccarat1",
        providerName: "Vivo",
      },
      GalaxyBaccarat2: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-2",
        imgUrl: "./images/Vivo/GalaxyBaccarat2.jpg",
        name: "Galaxy Baccarat 2",
        providerName: "Vivo",
      },
      GalaxyBaccarat3: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-3",
        imgUrl: "./images/Vivo/GalaxyBaccarat3.jpg",
        name: "Galaxy Baccarat 3",
        providerName: "Vivo",
      },
      GalaxyRoulette: {
        redirectUrl: "/casino/vivo-galaxy-roulette",
        imgUrl: "./images/Vivo/GalaxyRoulette.jpg",
        name: "Galaxy Roulette",
        providerName: "Vivo",
      },
      MacauBaccarat: {
        redirectUrl: "/casino/vivo-macau-baccarat",
        imgUrl: "./images/Vivo/MacauBaccarat.jpg",
        name: "Macau Baccarat",
        providerName: "Vivo",
      },
      Oracle360Roulette: {
        redirectUrl: "/casino/vivo-oracle-360-roulette",
        imgUrl: "./images/Vivo/Oracle360Roulette.jpg",
        name: "Oracle 360 Roulette",
        providerName: "Vivo",
      },
      OracleBlazeRoulette: {
        redirectUrl: "/casino/vivo-oracle-blaze-roulette",
        imgUrl: "./images/Vivo/OracleBlazeRoulette.jpg",
        name: "Oracle Blaze Roulette",
        providerName: "Vivo",
      },
      PortomasoRoulette: {
        redirectUrl: "/casino/vivo-portomaso-roulette",
        imgUrl: "./images/Vivo/PortomasoRoulette.jpg",
        name: "Portomaso Roulette",
        providerName: "Vivo",
      },
      TeenPatti: {
        redirectUrl: "/casino/vivo-teen-patti",
        imgUrl: "./images/Vivo/TeenPatti.jpg",
        name: "Teen Patti",
        providerName: "Vivo",
      },
    },
    slide2: {
      VABacarat1: {
        redirectUrl: "/casino/vivo-va-bacarat-1",
        imgUrl: "./images/Vivo/VABacarat1.jpg",
        name: "VA Bacarat 1",
        providerName: "Vivo",
      },
      VABacarat2: {
        redirectUrl: "/casino/vivo-va-bacarat-2",
        imgUrl: "./images/Vivo/VABacarat2.jpg",
        name: "VA Bacarat 2",
        providerName: "Vivo",
      },
      VABacarat3: {
        redirectUrl: "/casino/vivo-va-bacarat-3",
        imgUrl: "./images/Vivo/VABacarat3.jpg",
        name: "VA Bacarat 3",
        providerName: "Vivo",
      },
      VABacarat4: {
        redirectUrl: "/casino/vivo-va-bacarat-4",
        imgUrl: "./images/Vivo/VABacarat4.jpg",
        name: "VA Bacarat 4",
        providerName: "Vivo",
      },
      VABacarat5: {
        redirectUrl: "/casino/vivo-va-bacarat-5",
        imgUrl: "./images/Vivo/VABacarat5.jpg",
        name: "VA Bacarat 5",
        providerName: "Vivo",
      },
    },
    name: "Vivo",
  },
  prgmaticplaytab: {
    slide1: {
      CoffeeWild: {
        redirectUrl: "/casino/pp-coffee-wild",
        imgUrl: "./images/slots/pragmaticplay/CoffeeWild.jpg",
        name: "Coffee Wild",
        providerName: "Pragmatic Play",
      },
      DownTheRails: {
        redirectUrl: "/casino/pp-down-the-rails",
        code: "422",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DownTheRails.jpg",
        name: "Down The Rails",
        providerName: "Pragmatic Play",
      },
      FLoatingDragonMegaways: {
        redirectUrl: "/casino/pp-floating-dragon-megaways",
        code: "401",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FLoatingDragonMegaways.jpg",
        name: "FLoating Dragon Megaways",
        providerName: "Pragmatic Play",
      },
      GreedyWOlf: {
        redirectUrl: "/casino/pp-greedy-wolf",
        code: "411",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GreedyWOlf.jpg",
        name: "Greedy WOlf",
        providerName: "Pragmatic Play",
      },
      MagicMoneyMaze: {
        redirectUrl: "/casino/pp-magic-money-maze",
        code: "404",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MagicMoneyMaze.jpg",
        name: "Magic Money Maze",
        providerName: "Pragmatic Play",
      },
      bigbasssplash: {
        redirectUrl: "/casino/pp-big-bass-splash",
        code: "438",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_bigbasssplash.jpg",
        name: "Big Bass Splash",
        providerName: "Pragmatic Play",
      },
      bombbonanza: {
        redirectUrl: "/casino/pp-bomb-bonanza",
        code: "405",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_bombbonanza.jpg",
        name: "Bomb Bonanza",
        providerName: "Pragmatic Play",
      },
      cheekyemperor: {
        redirectUrl: "/casino/pp-cheeky-emperor",
        code: "414",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_cheekyemperor.jpg",
        name: "Cheeky Emperor",
        providerName: "Pragmatic Play",
      },
      cleocatra: {
        redirectUrl: "/casino/pp-cleocatra",
        code: "434",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_cleocatra.jpg",
        name: "Cleocatra",
        providerName: "Pragmatic Play",
      },
      cosmiccash: {
        redirectUrl: "/casino/pp-cosmic-cash",
        code: "435",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_cosmiccash.jpg",
        name: "Cosmic Cash",
        providerName: "Pragmatic Play",
      },
      mahjongpanda: {
        redirectUrl: "/casino/pp-mahjong-panda",
        code: "427",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_mahjongpanda.jpg",
        name: "Mahjong Panda",
        providerName: "Pragmatic Play",
      },
      firehot5: {
        redirectUrl: "/casino/pp-fire-hot-5",
        code: "436",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot5.jpg",
        name: "Fire Hot 5",
        providerName: "Pragmatic Play",
      },
    },
    slide2: {
      octobeerfortunes: {
        redirectUrl: "/casino/pp-octobeer-fortunes",
        code: "403",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_octobeerfortunes.jpg",
        name: "Octobeer Fortunes",
        providerName: "Pragmatic Play",
      },
      firehot20: {
        redirectUrl: "/casino/pp-fire-hot-20",
        code: "426",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot20.jpg",
        name: "Fire Hot 20",
        providerName: "Pragmatic Play",
      },
      Qweenofgod: {
        redirectUrl: "/casino/pp-queen-of-gods",
        code: "433",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_Qweenofgod.jpg",
        name: "Queen of Gods",
        providerName: "Pragmatic Play",
      },
      firehot40: {
        redirectUrl: "/casino/pp-fire-hot-40",
        code: "425",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot40.jpg",
        name: "Fire Hot 40",
        providerName: "Pragmatic Play",
      },
      sugarrush: {
        redirectUrl: "/casino/pp-sugar-rush",
        code: "415",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_sugar_rush.jpg",
        name: "Sugar Rush",
        providerName: "Pragmatic Play",
      },
      firehot100: {
        redirectUrl: "/casino/pp-fire-hot-100",
        code: "413",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot100.jpg",
        name: "Fire Hot 100",
        providerName: "Pragmatic Play",
      },
      thegreatstickup: {
        redirectUrl: "/casino/pp-the-great-stick-up",
        code: "225",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_thegreatstick-up.jpg",
        name: "The Great Stick-Up",
        providerName: "Pragmatic Play",
      },
      gorillamayhem: {
        redirectUrl: "/casino/pp-gorilla-mayhem",
        code: "402",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_gorillamayhem.jpg",
        name: "Gorilla Mayhem",
        providerName: "Pragmatic Play",
      },
      ZombieCarnival: {
        redirectUrl: "/casino/pp-zombie-carnival",
        code: "102",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_ZombieCarnival.jpg",
        name: "Zombie Carnival",
        providerName: "Pragmatic Play",
      },
      hottoburnextreme: {
        redirectUrl: "/casino/pp-hot-to-burn-extreme",
        code: "412",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_hottoburnextreme.jpg",
        name: "Hot to Burn Extreme",
        providerName: "Pragmatic Play",
      },
      ShiningHot5: {
        redirectUrl: "/casino/pp-shining-hot-5",
        code: "421",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot5.jpg",
        name: "Shining Hot 5",
        providerName: "Pragmatic Play",
      },
      KoiPond: {
        redirectUrl: "/casino/pp-koi-pond",
        code: "107",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_KoiPond.jpg",
        name: "Koi Pond",
        providerName: "Pragmatic Play",
      },
    },
    slide3: {
      ShiningHot20: {
        redirectUrl: "/casino/pp-shining-hot-20",
        code: "424",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot20.jpg",
        name: "Shining Hot 20",
        providerName: "Pragmatic Play",
      },
      littlegem: {
        redirectUrl: "/casino/pp-little-gem-hold-and-spin",
        code: "439",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_littlegem.jpg",
        name: "Little Gem Hold & Spin",
        providerName: "Pragmatic Play",
      },
      ShiningHot40: {
        redirectUrl: "/casino/pp-shining-hot-40",
        code: "428",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot40.jpg",
        name: "Shining Hot 40",
        providerName: "Pragmatic Play",
      },
      ShiningHot100: {
        redirectUrl: "/casino/pp-shining-hot-100",
        code: "430",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot100.jpg",
        name: "Shining Hot 100",
        providerName: "Pragmatic Play",
      },
      tropicaltiki: {
        redirectUrl: "/casino/pp-tropical-tiki",
        code: "431",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/tropicaltiki.jpg",
        name: "Tropical Tiki",
        providerName: "Pragmatic Play",
      },
      WildmanSuperBOnanza: {
        redirectUrl: "/casino/pp-wildman-super-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildmanSuperBOnanza.jpg",
        name: "Wildman Super Bonanza",
        providerName: "Pragmatic Play",
      },
      LionsGold5: {
        redirectUrl: "/casino/pp-5-lions-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/5LionsGold.jpg",
        name: "5 Lions Gold",
        providerName: "Pragmatic Play",
      },
      piggies7: {
        redirectUrl: "/casino/pp-7-piggies",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/7piggies.jpg",
        name: "7 piggies",
        providerName: "Pragmatic Play",
      },
      dragon888: {
        redirectUrl: "/casino/pp-888-dragons",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/888dragon.jpg",
        name: "888 dragon",
        providerName: "Pragmatic Play",
      },
      aladinandthesrcerer: {
        redirectUrl: "/casino/pp-aladdin-and-the-sorcerer",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/aladinandthesrcerer.jpg",
        name: "Aladin and the sorcerer",
        providerName: "Pragmatic Play",
      },
      ancientegypt: {
        redirectUrl: "/casino/pp-ancient-egypt",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ancientegypt.jpg",
        name: "Ancient egypt",
        providerName: "Pragmatic Play",
      },
      AncientEgyptClassic: {
        redirectUrl: "/casino/pp-ancient-egypt-classic",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AncientEgyptClassic.jpg",
        name: "Ancient Egypt Classic",
        providerName: "Pragmatic Play",
      },
    },
    slide4: {
      Asgard: {
        redirectUrl: "/casino/pp-asgard",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Asgard.jpg",
        name: "Asgard",
        providerName: "Pragmatic Play",
      },
      AztecGems: {
        redirectUrl: "/casino/pp-aztec-gems",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AztecGems.jpg",
        name: "Aztec Gems",
        providerName: "Pragmatic Play",
      },
      BuffaloKing: {
        redirectUrl: "/casino/pp-buffalo-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BuffaloKing.jpg",
        name: "Buffalo King",
        providerName: "Pragmatic Play",
      },
      CaishensCash: {
        redirectUrl: "/casino/pp-caishens-cash",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Caishen'sCash.jpg",
        name: "Caishen'sCash",
        providerName: "Pragmatic Play",
      },
      ChilliHeat: {
        redirectUrl: "/casino/pp-chilli-heat",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChilliHeat.jpg",
        name: "Chilli Heat",
        providerName: "Pragmatic Play",
      },
      DaVincisTreasure: {
        redirectUrl: "/casino/pp-da-vincis-treasure",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DaVinci'sTreasure.jpg",
        name: "DaVinci's Treasure",
        providerName: "Pragmatic Play",
      },
      diamondsareforever3line: {
        redirectUrl: "/casino/pp-diamonds-are-forever-3-lines",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/diamondsareforever3line.jpg",
        name: "Diamonds are forever 3 line",
        providerName: "Pragmatic Play",
      },
      EgyptianFortunes: {
        redirectUrl: "/casino/pp-egyptian-fortunes",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EgyptianFortunes.jpg",
        name: "Egyptian Fortunes",
        providerName: "Pragmatic Play",
      },
      ExtraJuicy: {
        redirectUrl: "/casino/pp-extra-juicy",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ExtraJuicy.jpg",
        name: "Extra Juicy ",
        providerName: "Pragmatic Play",
      },
      FairytaleFortune: {
        redirectUrl: "/casino/pp-fairytale-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FairytaleFortune.jpg",
        name: "Fairytale Fortune ",
        providerName: "Pragmatic Play",
      },
      Fire88: {
        redirectUrl: "/casino/pp-fire-88",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Fire88.jpg",
        name: "Fire 88",
        providerName: "Pragmatic Play",
      },
      FireStrike: {
        redirectUrl: "/casino/pp-fire-strike",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FireStrike.jpg",
        name: "Fire Strike",
        providerName: "Pragmatic Play",
      },
    },
    slide5: {
      GoldenPig: {
        redirectUrl: "/casino/pp-golden-pig",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldenPig.jpg",
        name: "Golden Pig",
        providerName: "Pragmatic Play",
      },
      GoldRush: {
        redirectUrl: "/casino/pp-gold-rush",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldRush.jpg",
        name: "Gold Rush",
        providerName: "Pragmatic Play",
      },
      Greekgods: {
        redirectUrl: "/casino/pp-greek-gods",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Greekgods.jpg",
        name: "Greek gods",
        providerName: "Pragmatic Play",
      },
      HerculesandPegasus: {
        redirectUrl: "/casino/pp-hercules-and-pegasus",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HerculesandPegasus.jpg",
        name: "Hercules and Pegasus",
        providerName: "Pragmatic Play",
      },
      HoneyHoneyHoney: {
        redirectUrl: "/casino/pp-honey-honey-honey",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HoneyHoneyHoney.jpg",
        name: "Honey Honey Honey",
        providerName: "Pragmatic Play",
      },
      HotChilli: {
        redirectUrl: "/casino/pp-hot-chilli",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HotChilli.jpg",
        name: "Hot Chilli",
        providerName: "Pragmatic Play",
      },
      JadeButterfly: {
        redirectUrl: "/casino/pp-jade-butterfly",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JadeButterfly.jpg",
        name: "Jade Butterfly",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheAztecTreasure: {
        redirectUrl: "/casino/pp-john-hunter-and-the-aztec-treasure",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/JohnHunterandtheAztecTreasure.jpg",
        name: "John Hunter and the Aztec Treasure",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheTomboftheScarabQueen: {
        redirectUrl: "/casino/pp-john-hunter-and-the-tomb-of-the-scarab-queen",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/JohnHunterandtheTomboftheScarabQueen.jpg",
        name: "John Hunter and the Tomb of the ScarabQueen",
        providerName: "Pragmatic Play",
      },
      JokersJewels: {
        redirectUrl: "/casino/pp-jokers-jewels",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JokersJewels.jpg",
        name: "Jokers Jewels",
        providerName: "Pragmatic Play",
      },
      leprechaun: {
        redirectUrl: "/casino/pp-leprechaun-carol",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/leprechaun.jpg",
        name: "Leprechaun",
        providerName: "Pragmatic Play",
      },
      LeprechaunSong: {
        redirectUrl: "/casino/pp-leprechaun-song",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LeprechaunSong.jpg",
        name: "Leprechaun Song",
        providerName: "Pragmatic Play",
      },
    },
    slide6: {
      LuckyNewYear: {
        redirectUrl: "/casino/pp-lucky-new-year",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyNewYear.jpg",
        name: "Lucky New Year",
        providerName: "Pragmatic Play",
      },
      MadameDestiny: {
        redirectUrl: "/casino/pp-madame-destiny",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MadameDestiny.jpg",
        name: "Madame Destiny",
        providerName: "Pragmatic Play",
      },
      MagicJourney: {
        redirectUrl: "/casino/pp-magic-journey",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MagicJourney.jpg",
        name: "Magic Journey",
        providerName: "Pragmatic Play",
      },
      Monkeymadness: {
        redirectUrl: "/casino/pp-monkey-madness",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Monkeymadness.jpg",
        name: "Monkey madness",
        providerName: "Pragmatic Play",
      },
      MonkeyWarrior: {
        redirectUrl: "/casino/pp-monkey-warrior",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MonkeyWarrior.jpg",
        name: "Monkey Warrior",
        providerName: "Pragmatic Play",
      },
      Mustanggold: {
        redirectUrl: "/casino/pp-mustang-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Mustanggold.jpg",
        name: "Mustang gold",
        providerName: "Pragmatic Play",
      },
      PekingLuck: {
        redirectUrl: "/casino/pp-peking-luck",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PekingLuck.jpg",
        name: "Peking Luck",
        providerName: "Pragmatic Play",
      },
      PirateGold: {
        redirectUrl: "/casino/pp-pirate-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PirateGold.jpg",
        name: "Pirate Gold",
        providerName: "Pragmatic Play",
      },
      ReleasetheKraken: {
        redirectUrl: "/casino/pp-release-the-kraken",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ReleasetheKraken.jpg",
        name: "Release the Kraken",
        providerName: "Pragmatic Play",
      },
      Safariking: {
        redirectUrl: "/casino/pp-safari-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Safariking.jpg",
        name: "Safari king",
        providerName: "Pragmatic Play",
      },
      Santa: {
        redirectUrl: "/casino/pp-santa",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Santa.jpg",
        name: "Santa",
        providerName: "Pragmatic Play",
      },
      Super7: {
        redirectUrl: "/casino/pp-super-7s",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Super7.jpg",
        name: "Super 7",
        providerName: "Pragmatic Play",
      },
    },
    slide7: {
      SuperJoker: {
        redirectUrl: "/casino/pp-super-joker",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SuperJoker.jpg",
        name: "Super Joker",
        providerName: "Pragmatic Play",
      },
      SweetBonanza: {
        redirectUrl: "/casino/pp-sweet-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SweetBonanza.jpg",
        name: "Sweet Bonanza",
        providerName: "Pragmatic Play",
      },
      Sweetbonanzaxmas: {
        redirectUrl: "/casino/pp-sweet-bonanza-xmas",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Sweetbonanzaxmas.jpg",
        name: "Sweet bonanza xmas",
        providerName: "Pragmatic Play",
      },
      TheDogHouse: {
        redirectUrl: "/casino/pp-the-dog-house",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheDogHouse.jpg",
        name: "The Dog House",
        providerName: "Pragmatic Play",
      },
      TheGreatChickenEscape: {
        redirectUrl: "/casino/pp-the-great-chicken-escape",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheGreatChickenEscape.jpg",
        name: "The Great Chicken Escape",
        providerName: "Pragmatic Play",
      },
      TreasureHorse: {
        redirectUrl: "/casino/pp-treasure-horse",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TreasureHorse.jpg",
        name: "Treasure Horse",
        providerName: "Pragmatic Play",
      },
      TreeofRiches: {
        redirectUrl: "/casino/pp-tree-of-riches",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TreeofRiches.jpg",
        name: "Tree of Riches",
        providerName: "Pragmatic Play",
      },
      tripledragons: {
        redirectUrl: "/casino/pp-triple-dragons",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/tripledragons.jpg",
        name: "Triple dragons",
        providerName: "Pragmatic Play",
      },
      TripleTigers: {
        redirectUrl: "/casino/pp-triple-tigers",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TripleTigers.jpg",
        name: "Triple Tigers",
        providerName: "Pragmatic Play",
      },
      VampiresVSWolves: {
        redirectUrl: "/casino/pp-vampires-vs-wolves",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/VampiresVSWolves.jpg",
        name: "Vampires VS Wolves",
        providerName: "Pragmatic Play",
      },
      Vegasnights: {
        redirectUrl: "/casino/pp-vegas-nights",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Vegasnights.jpg",
        name: "Vegas nights",
        providerName: "Pragmatic Play",
      },
      WildGladiator: {
        redirectUrl: "/casino/pp-wild-gladiator",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildGladiator.jpg",
        name: "Wild Gladiator",
        providerName: "Pragmatic Play",
      },
      WildPixies: {
        redirectUrl: "/casino/pp-wild-pixies",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildPixies.jpg",
        name: "Wild Pixies",
        providerName: "Pragmatic Play",
      },
    },
    slide8: {
      geniewishemin3: {
        redirectUrl: "/casino/pp-3-genie-wishes",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/3geniewishe-min.jpg",
        name: "3 Geniewishe",
        providerName: "Pragmatic Play",
      },
      KingdomsBattleofRedCliffsmin3: {
        redirectUrl: "/casino/pp-3-kingdoms-battle-of-red-cliffs",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/3KingdomsBattleofRedCliffs-min.jpg",
        name: "3 Kingdoms BattleofRedCliffs",
        providerName: "Pragmatic Play",
      },
      monkeys7min: {
        redirectUrl: "/casino/pp-7-monkeys",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/7monkeys-min.jpg",
        name: "7 monkeys",
        providerName: "Pragmatic Play",
      },
      Gold888: {
        redirectUrl: "/casino/pp-888-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/888Gold-min.jpg",
        name: "Gold 888",
        providerName: "Pragmatic Play",
      },
      Beowulf: {
        redirectUrl: "/casino/pp-beowulf",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Beowulf.jpg",
        name: "Beowulf",
        providerName: "Pragmatic Play",
      },
      Devils13: {
        redirectUrl: "/casino/pp-devils-13",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Devils13.jpg",
        name: "Devils 13",
        providerName: "Pragmatic Play",
      },
      DiamondStrike: {
        redirectUrl: "/casino/pp-diamond-strike",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DiamondStrike.jpg",
        name: "Diamond Strike",
        providerName: "Pragmatic Play",
      },
      DragonKingdom: {
        redirectUrl: "/casino/pp-dragon-kingdom",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragonKingdom.jpg",
        name: "Dragon Kingdom",
        providerName: "Pragmatic Play",
      },
      dwarvengolddeluxe: {
        redirectUrl: "/casino/pp-dwarven-gold-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/dwarvengolddeluxe.jpg",
        name: "Dwarven gold deluxe",
        providerName: "Pragmatic Play",
      },
      GoldTrain: {
        redirectUrl: "/casino/pp-gold-train",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldTrain.jpg",
        name: "Gold Train",
        providerName: "Pragmatic Play",
      },
      Greatreef: {
        redirectUrl: "/casino/pp-great-reef",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Greatreef.jpg",
        name: "Great reef",
        providerName: "Pragmatic Play",
      },
      HerculesSonOfZeus: {
        redirectUrl: "/casino/pp-hercules-son-of-zeus",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HerculesSonOfZeus.jpg",
        name: "Hercules Son Of Zeus",
        providerName: "Pragmatic Play",
      },
      Hotsafari: {
        redirectUrl: "/casino/pp-hot-safari",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Hotsafari.jpg",
        name: "Hot safari",
        providerName: "Pragmatic Play",
      },
      irishcharms: {
        redirectUrl: "/casino/pp-irish-charms",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/irishcharms.jpg",
        name: "Irish charms",
        providerName: "Pragmatic Play",
      },
      JourneytotheWest: {
        redirectUrl: "/casino/pp-journey-to-the-west",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JourneytotheWest.jpg",
        name: "Journey to the West",
        providerName: "Pragmatic Play",
      },
    },
    slide9: {
      ladygodiva: {
        redirectUrl: "/casino/pp-lady-godiva",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ladygodiva.jpg",
        name: "Lady godiva",
        providerName: "Pragmatic Play",
      },
      LuckyDragons: {
        redirectUrl: "/casino/pp-lucky-dragons",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyDragons.jpg",
        name: "Lucky Dragons",
        providerName: "Pragmatic Play",
      },
      MightKong: {
        redirectUrl: "/casino/pp-mighty-kong",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MightKong.jpg",
        name: "Mighty Kong",
        providerName: "Pragmatic Play",
      },
      MoneyRoll: {
        redirectUrl: "/casino/pp-money-roll",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MoneyRoll.jpg",
        name: "Money Roll",
        providerName: "Pragmatic Play",
      },
      PandasFortune: {
        redirectUrl: "/casino/pp-pandas-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PandasFortune.jpg",
        name: "Pandas Fortune",
        providerName: "Pragmatic Play",
      },
      Aztechking: {
        redirectUrl: "/casino/pp-aztec-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Aztechking.jpg",
        name: "Aztec king",
        providerName: "Pragmatic Play",
      },
      Barnfestival: {
        redirectUrl: "/casino/pp-barn-festival",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Barnfestival.jpg",
        name: "Barn festival",
        providerName: "Pragmatic Play",
      },
      bullfiesta: {
        redirectUrl: "/casino/pp-bull-fiesta",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/bullfiesta.jpg",
        name: "Bull fiesta",
        providerName: "Pragmatic Play",
      },
      Chickenchase: {
        redirectUrl: "/casino/pp-chicken-chase",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Chickenchase.jpg",
        name: "Chicken chase",
        providerName: "Pragmatic Play",
      },
      colossalcashzone: {
        redirectUrl: "/casino/pp-colossal-cash-zone",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/colossalcashzone.jpg",
        name: "Colossal cash zone",
        providerName: "Pragmatic Play",
      },
      Discolady: {
        redirectUrl: "/casino/pp-disco-lady",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Discolady.jpg",
        name: "Discolady",
        providerName: "Pragmatic Play",
      },
      Drillthatgold: {
        redirectUrl: "/casino/pp-drill-that-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Drillthatgold.jpg",
        name: "Drill that gold",
        providerName: "Pragmatic Play",
      },
    },
    slide10: {
      eyeofcleopatra: {
        redirectUrl: "/casino/pp-eye-of-cleopatra",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/eyeofcleopatra.jpg",
        name: "Eye of cleopatra",
        providerName: "Pragmatic Play",
      },

      Goblinheistpowernudge: {
        redirectUrl: "/casino/pp-goblin-heist-powernudge",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Goblinheistpowernudge.jpg",
        name: "Goblin heist powernudge",
        providerName: "Pragmatic Play",
      },
      northguardians: {
        redirectUrl: "/casino/pp-north-guardians",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/northguardians.jpg",
        name: "North guardians",
        providerName: "Pragmatic Play",
      },
      mightyofRa: {
        redirectUrl: "/casino/pp-might-of-ra",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/mightyofRa.jpg",
        name: "Might of Ra",
        providerName: "Pragmatic Play",
      },
      Rainbowgold: {
        redirectUrl: "/casino/pp-rainbow-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Rainbowgold.jpg",
        name: "Rainbow gold",
        providerName: "Pragmatic Play",
      },
      snakesandladders: {
        redirectUrl: "/casino/pp-snakes-and-ladders-megadice",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/snakesandladders.jpg",
        name: "Snakes and ladders",
        providerName: "Pragmatic Play",
      },
      spaceman: {
        redirectUrl: "/casino/pp-spaceman",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/spaceman.jpg",
        name: "Spaceman",
        providerName: "Pragmatic Play",
      },
      Spiritofadventure: {
        redirectUrl: "/casino/pp-spirit-of-adventure",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Spiritofadventure.jpg",
        name: "Spirit of adventure",
        providerName: "Pragmatic Play",
      },
      Theultimate5: {
        redirectUrl: "/casino/pp-the-ultimate-5",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Theultimate5.jpg",
        name: "The ultimate 5",
        providerName: "Pragmatic Play",
      },
      Tictactake: {
        redirectUrl: "/casino/pp-tic-tac-take",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Tictactake.jpg",
        name: "Tic tac take",
        providerName: "Pragmatic Play",
      },
      wildbeachparty: {
        redirectUrl: "/casino/pp-wild-beach-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/wildbeachparty.jpg",
        name: "wild beach party",
        providerName: "Pragmatic Play",
      },
      wildwestgoldmegaways: {
        redirectUrl: "/casino/pp-wild-west-gold-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/wildwestgoldmegaways.jpg",
        name: "wild west Goldmegaways",
        providerName: "Pragmatic Play",
      },
    },
    slide11: {
      LionsDance5: {
        redirectUrl: "/casino/pp-5-lions-dance",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/5LionsDance.jpg",
        name: "5 Lions Dances",
        providerName: "Pragmatic Play",
      },
      LionsMegaways5: {
        redirectUrl: "/casino/pp-5-lions-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/5LionsMegaways.jpg",
        name: "5 Lions Megaways",
        providerName: "Pragmatic Play",
      },
      AmazingMoneyMachine: {
        redirectUrl: "/casino/pp-amazing-money-machine",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AmazingMoneyMachine.jpg",
        name: "Amazing Money Machine",
        providerName: "Pragmatic Play",
      },
      AztecGemsDeluxe: {
        redirectUrl: "/casino/pp-aztec-gems-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AztecGemsDeluxe.jpg",
        name: "Aztec Gems Deluxe",
        providerName: "Pragmatic Play",
      },
      AztecKingMegaways: {
        redirectUrl: "/casino/pp-aztec-king-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AztecKingMegaways.jpg",
        name: "Aztec King Megaways",
        providerName: "Pragmatic Play",
      },
      BigBassBonanza: {
        redirectUrl: "/casino/pp-big-bass-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BigBassBonanza.jpg",
        name: "Big Bass Bonanza",
        providerName: "Pragmatic Play",
      },
      BigBassBonanzaMegaways: {
        redirectUrl: "/casino/pp-big-bass-bonanza-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BigBassBonanzaMegaways.jpg",
        name: "Big Bass Bonanza Megaways",
        providerName: "Pragmatic Play",
      },
      BigJuan: {
        redirectUrl: "/casino/pp-big-juan",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BigJuan.jpg",
        name: "Big Juan",
        providerName: "Pragmatic Play",
      },
      BonanzaGold: {
        redirectUrl: "/casino/pp-bonanza-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BonanzaGold.jpg",
        name: "Bonanza Gold",
        providerName: "Pragmatic Play",
      },
      BookofFallen: {
        redirectUrl: "/casino/pp-book-of-the-fallen",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BookofFallen.jpg",
        name: "Book of Fallen",
        providerName: "Pragmatic Play",
      },
      BookofKingdoms: {
        redirectUrl: "/casino/pp-book-of-kingdoms",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BookofKingdoms.jpg",
        name: "Book of Kingdoms",
        providerName: "Pragmatic Play",
      },
      BountyGold: {
        redirectUrl: "/casino/pp-bounty-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BountyGold.jpg",
        name: "Bounty Gold",
        providerName: "Pragmatic Play",
      },
    },
    slide12: {
      BroncoSpirit: {
        redirectUrl: "/casino/pp-bronco-spirit",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BroncoSpirit.jpg",
        name: "Bronco Spirit",
        providerName: "Pragmatic Play",
      },
      BubblePop: {
        redirectUrl: "/casino/pp-bubble-pop",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BubblePop.jpg",
        name: "Bubble Pop",
        providerName: "Pragmatic Play",
      },
      BuffaloKingMegaways: {
        redirectUrl: "/casino/pp-buffalo-king-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BuffaloKingMegaways.jpg",
        name: "Buffalo King Megaways",
        providerName: "Pragmatic Play",
      },
      CandyVillage: {
        redirectUrl: "/casino/pp-candy-village",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CandyVillage.jpg",
        name: "Candy Village",
        providerName: "Pragmatic Play",
      },
      CashBonanza: {
        redirectUrl: "/casino/pp-cash-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CashBonanza.jpg",
        name: "Cash Bonanza",
        providerName: "Pragmatic Play",
      },
      CashElevator: {
        redirectUrl: "/casino/pp-cash-elevator",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CashElevator.jpg",
        name: "Cash Elevator",
        providerName: "Pragmatic Play",
      },
      ChickenDrop: {
        redirectUrl: "/casino/pp-chicken-drop",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChickenDrop.jpg",
        name: "Chicken Drop",
        providerName: "Pragmatic Play",
      },
      ChilliHeatMegaways: {
        redirectUrl: "/casino/pp-chilli-heat-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChilliHeatMegaways.jpg",
        name: "Chilli Heat Megaways",
        providerName: "Pragmatic Play",
      },
      ChristmasBigBassBonanza: {
        redirectUrl: "/casino/pp-christmas-big-bass-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChristmasBigBassBonanza.jpg",
        name: "Christmas BigBass Bonanza",
        providerName: "Pragmatic Play",
      },
      ChristmasCarolMegaways: {
        redirectUrl: "/casino/pp-christmas-carol-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChristmasCarolMegaways.jpg",
        name: "Christmas Carol Megaways",
        providerName: "Pragmatic Play",
      },
      CongoCash: {
        redirectUrl: "/casino/pp-congo-cash",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CongoCash.jpg",
        name: "Congo Cash",
        providerName: "Pragmatic Play",
      },
      CowboysGold: {
        redirectUrl: "/casino/pp-cowboys-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CowboysGold.jpg",
        name: "Cowboys Gold",
        providerName: "Pragmatic Play",
      },
    },
    slide13: {
      CrystalCavernsMegaways: {
        redirectUrl: "/casino/pp-crystal-caverns-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CrystalCavernsMegaways.jpg",
        name: "Crystal Caverns Megaways",
        providerName: "Pragmatic Play",
      },
      CurseoftheWerewolfMegaways: {
        redirectUrl: "/casino/pp-curse-of-the-werewolf-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CurseoftheWerewolf-Megaways.jpg",
        name: "Curse of the Werewolf-Megaways",
        providerName: "Pragmatic Play",
      },
      DanceParty: {
        redirectUrl: "/casino/pp-dance-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DanceParty.jpg",
        name: "Dance Party",
        providerName: "Pragmatic Play",
      },
      DayofDead: {
        redirectUrl: "/casino/pp-day-of-dead",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DayofDead.jpg",
        name: "Day of Dead",
        providerName: "Pragmatic Play",
      },
      DragoJewelsofFortune: {
        redirectUrl: "/casino/pp-drago-jewels-of-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragoJewelsofFortune.jpg",
        name: "Drago Jewels of Fortune",
        providerName: "Pragmatic Play",
      },
      DragonHotHoldandSpin: {
        redirectUrl: "/casino/pp-dragon-hot-hold-and-spin",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragonHotHoldandSpin.jpg",
        name: "DragonHot HoldandSpin",
        providerName: "Pragmatic Play",
      },
      DragonKingdomEyesofFire: {
        redirectUrl: "/casino/pp-dragon-kingdom-eyes-of-fire",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragonKingdomEyesofFire.jpg",
        name: "Dragon Kingdom EyesofFire",
        providerName: "Pragmatic Play",
      },
      ElementalGemsMegaways: {
        redirectUrl: "/casino/pp-elemental-gems-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ElementalGemsMegaways.jpg",
        name: "Elemental Gems Megaways",
        providerName: "Pragmatic Play",
      },
      EmeraldKing: {
        redirectUrl: "/casino/pp-emerald-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmeraldKing.jpg",
        name: "Emerald King",
        providerName: "Pragmatic Play",
      },
      EmeraldKingRainbowRoad: {
        redirectUrl: "/casino/pp-emerald-king-rainbow-road",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmeraldKingRainbowRoad.jpg",
        name: "Emerald King RainbowRoad",
        providerName: "Pragmatic Play",
      },
      EmperorCaishen: {
        redirectUrl: "/casino/pp-emperor-caishen",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmperorCaishen.jpg",
        name: "Emperor Caishen",
        providerName: "Pragmatic Play",
      },
      EmptytheBank: {
        redirectUrl: "/casino/pp-empty-the-bank",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmptytheBank.jpg",
        name: "Empty the Bank",
        providerName: "Pragmatic Play",
      },
    },
    slide14: {
      ExtraJuicyMegaways: {
        redirectUrl: "/casino/pp-extra-juicy-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ExtraJuicyMegaways.jpg",
        name: "Extra Juicy Megaways",
        providerName: "Pragmatic Play",
      },
      EyeoftheStorm: {
        redirectUrl: "/casino/pp-eye-of-the-storm",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EyeoftheStorm.jpg",
        name: "Eye of the Storm",
        providerName: "Pragmatic Play",
      },
      FishinReels: {
        redirectUrl: "/casino/pp-fishin-reels",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FishinReels.jpg",
        name: "Fishin Reels",
        providerName: "Pragmatic Play",
      },
      FloatingDragon: {
        redirectUrl: "/casino/pp-floating-dragon",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FloatingDragon.jpg",
        name: "Floating Dragon",
        providerName: "Pragmatic Play",
      },
      FortuneofGiza: {
        redirectUrl: "/casino/pp-fortune-of-giza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FortuneofGiza.jpg",
        name: "Fortune of Giza",
        providerName: "Pragmatic Play",
      },
      FruitParty: {
        redirectUrl: "/casino/pp-fruit-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FruitParty.jpg",
        name: "Fruit Party",
        providerName: "Pragmatic Play",
      },
      FruitParty2: {
        redirectUrl: "/casino/pp-fruit-party-2",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FruitParty2.jpg",
        name: "Fruit Party 2",
        providerName: "Pragmatic Play",
      },
      FruitRainbow: {
        redirectUrl: "/casino/pp-fruit-rainbow",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FruitRainbow.jpg",
        name: "Fruit Rainbow",
        providerName: "Pragmatic Play",
      },
      FuFuFu: {
        redirectUrl: "/casino/pp-fu-fu-fu",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FuFuFu.jpg",
        name: "Fu Fu Fu",
        providerName: "Pragmatic Play",
      },
      GatesofOlympus: {
        redirectUrl: "/casino/pp-gates-of-olympus",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GatesofOlympus.jpg",
        name: "Gates of Olympus",
        providerName: "Pragmatic Play",
      },
      GatesofValhalla: {
        redirectUrl: "/casino/pp-gates-of-valhalla",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GatesofValhalla.jpg",
        name: "Gates of Valhalla",
        providerName: "Pragmatic Play",
      },
      GemsBonanza: {
        redirectUrl: "/casino/pp-gems-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GemsBonanza.jpg",
        name: "Gems Bonanza",
        providerName: "Pragmatic Play",
      },
    },
    slide15: {
      GoldenBeauty: {
        redirectUrl: "/casino/pp-golden-beauty",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldenBeauty.jpg",
        name: "Golden Beauty",
        providerName: "Pragmatic Play",
      },
      GoldenOx: {
        redirectUrl: "/casino/pp-golden-ox",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldenOx.jpg",
        name: "Golden Ox",
        providerName: "Pragmatic Play",
      },
      GoldParty: {
        redirectUrl: "/casino/pp-gold-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldParty.jpg",
        name: "Gold Party",
        providerName: "Pragmatic Play",
      },
      GreatRhinoDeluxe: {
        redirectUrl: "/casino/pp-great-rhino-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GreatRhinoDeluxe.jpg",
        name: "Great Rhino Deluxe",
        providerName: "Pragmatic Play",
      },
      GreatRhinoMegaways: {
        redirectUrl: "/casino/pp-great-rhino-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GreatRhinoMegaways.jpg",
        name: "Great Rhino Megaways",
        providerName: "Pragmatic Play",
      },
      HeartofRio: {
        redirectUrl: "/casino/pp-heart-of-rio",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HeartofRio.jpg",
        name: "Heart of Rio",
        providerName: "Pragmatic Play",
      },
      HockeyAttack: {
        redirectUrl: "/casino/pp-hockey-attack",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HockeyAttack.jpg",
        name: "Hockey Attack",
        providerName: "Pragmatic Play",
      },
      HokkaidoWolf: {
        redirectUrl: "/casino/pp-hokkaido-wolf",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HokkaidoWolf.jpg",
        name: "Hokkaido Wolf",
        providerName: "Pragmatic Play",
      },
      HotFiesta: {
        redirectUrl: "/casino/pp-hot-fiesta",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HotFiesta.jpg",
        name: "Hot Fiesta",
        providerName: "Pragmatic Play",
      },
      HottoBurn: {
        redirectUrl: "/casino/pp-hot-to-burn",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HottoBurn.jpg",
        name: "Hot to Burn",
        providerName: "Pragmatic Play",
      },
      HottoBurnHoldandSpin: {
        redirectUrl: "/casino/pp-hot-to-burn-hold-and-spin",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HottoBurnHoldandSpin.jpg",
        name: "Hot to Burn HoldandSpin",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheBookofTut: {
        redirectUrl: "/casino/pp-john-hunter-and-the-book-of-tut",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JohnHunterandtheBookofTut.jpg",
        name: "John Hunter and the Book of Tut",
        providerName: "Pragmatic Play",
      },
    },
    slide16: {
      JohnHunterandtheMayanGods: {
        redirectUrl: "/casino/pp-john-hunter-and-the-mayan-gods",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JohnHunterandtheMayanGods.jpg",
        name: "John Hunter and the MayanGods",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheQuestforBermudaRiches: {
        redirectUrl: "/casino/pp-john-hunter-and-the-quest-for-bermuda-riches",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/JohnHunterandtheQuestforBermudaRiches.jpg",
        name: "John Hunter and the Quest for BermudaRiches",
        providerName: "Pragmatic Play",
      },
      JokerKing: {
        redirectUrl: "/casino/pp-joker-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JokerKing.jpg",
        name: "Joker King",
        providerName: "Pragmatic Play",
      },
      JuicyFruits: {
        redirectUrl: "/casino/pp-juicy-fruits",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JuicyFruits.jpg",
        name: "Juicy Fruits",
        providerName: "Pragmatic Play",
      },
      JungleGorilla: {
        redirectUrl: "/casino/pp-jungle-gorilla",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JungleGorilla.jpg",
        name: "Jungle Gorilla",
        providerName: "Pragmatic Play",
      },
      LuckyDragonBall: {
        redirectUrl: "/casino/pp-lucky-dragon-ball",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyDragonBall.jpg",
        name: "Lucky DragonBall",
        providerName: "Pragmatic Play",
      },
      LuckyGraceAndCharm: {
        redirectUrl: "/casino/pp-lucky-grace-and-charm",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyGraceAndCharm.jpg",
        name: "Lucky Grace And Charm",
        providerName: "Pragmatic Play",
      },
      LuckyLightning: {
        redirectUrl: "/casino/pp-lucky-lightning",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyLightning.jpg",
        name: "Lucky Lightning",
        providerName: "Pragmatic Play",
      },
      LuckyNewYearTigerTreasures: {
        redirectUrl: "/casino/pp-lucky-new-year-tiger-treasures",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyNewYearTigerTreasures.jpg",
        name: "Lucky NewYear Tiger Treasures",
        providerName: "Pragmatic Play",
      },
      MadameDestinyMegaways: {
        redirectUrl: "/casino/pp-madame-destiny-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MadameDestinyMegaways.jpg",
        name: "Madame Destiny Megaways",
        providerName: "Pragmatic Play",
      },
      MagiciansSecrets: {
        redirectUrl: "/casino/pp-magicians-secrets",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Magician'sSecrets.jpg",
        name: "Magician's Secrets",
        providerName: "Pragmatic Play",
      },
      MasterJoker: {
        redirectUrl: "/casino/pp-master-joker",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MasterJoker.jpg",
        name: "Master Joker",
        providerName: "Pragmatic Play",
      },
    },
    slide17: {
      MoneyMoneyMoney: {
        redirectUrl: "/casino/pp-money-money-money",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MoneyMoneyMoney.jpg",
        name: "Money Money Money",
        providerName: "Pragmatic Play",
      },
      Mysterious: {
        redirectUrl: "/casino/pp-mysterious",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Mysterious.jpg",
        name: "Mysterious",
        providerName: "Pragmatic Play",
      },
      MysteriousEgypt: {
        redirectUrl: "/casino/pp-mysterious-egypt",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MysteriousEgypt.jpg",
        name: "Mysterious Egypt",
        providerName: "Pragmatic Play",
      },
      MysticChief: {
        redirectUrl: "/casino/pp-mystic-chief",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MysticChief.jpg",
        name: "Mystic Chief",
        providerName: "Pragmatic Play",
      },
      PandaFortune2: {
        redirectUrl: "/casino/pp-panda-fortune-2",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PandaFortune2.jpg",
        name: "Panda Fortune 2",
        providerName: "Pragmatic Play",
      },
      PhoenixForge: {
        redirectUrl: "/casino/pp-phoenix-forge",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Phoenix-Forge.jpg",
        name: "Phoenix Forge",
        providerName: "Pragmatic Play",
      },
      PiggyBankBills: {
        redirectUrl: "/casino/pp-piggy-bank-bills",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PiggyBankBills.jpg",
        name: "Piggy Bank Bills",
        providerName: "Pragmatic Play",
      },
      PirateGoldDeluxe: {
        redirectUrl: "/casino/pp-pirate-gold-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PirateGoldDeluxe.jpg",
        name: "Pirate Gold Deluxe",
        providerName: "Pragmatic Play",
      },
      PowerofThorMegaways: {
        redirectUrl: "/casino/pp-power-of-thor-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PowerofThorMegaways.jpg",
        name: "Power of Thor Megaways",
        providerName: "Pragmatic Play",
      },
      PyramidBonanza: {
        redirectUrl: "/casino/pp-pyramid-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PyramidBonanza.jpg",
        name: "Pyramid Bonanza",
        providerName: "Pragmatic Play",
      },
      PyramidKing: {
        redirectUrl: "/casino/pp-pyramid-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PyramidKing.jpg",
        name: "Pyramid King",
        providerName: "Pragmatic Play",
      },
      RagingBull: {
        redirectUrl: "/casino/pp-raging-bull",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RagingBull.jpg",
        name: "Raging Bull",
        providerName: "Pragmatic Play",
      },
    },
    slide18: {
      ReturnoftheDead: {
        redirectUrl: "/casino/pp-return-of-the-dead",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ReturnoftheDead.jpg",
        name: "Return of the Dead",
        providerName: "Pragmatic Play",
      },
      RiseofGizaPowerNudge: {
        redirectUrl: "/casino/pp-rise-of-giza-powernudge",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RiseofGizaPowerNudge.jpg",
        name: "Rise of Giza PowerNudge",
        providerName: "Pragmatic Play",
      },
      RiseofSamurai: {
        redirectUrl: "/casino/pp-rise-of-samurai",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RiseofSamurai.jpg",
        name: "Rise of Samurai",
        providerName: "Pragmatic Play",
      },
      RiseofSamuraiMegaways: {
        redirectUrl: "/casino/pp-rise-of-samurai-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RiseofSamuraiMegaways.jpg",
        name: "Rise of Samurai Megaways",
        providerName: "Pragmatic Play",
      },
      RockVegas: {
        redirectUrl: "/casino/pp-rock-vegas",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RockVegas.jpg",
        name: "Rock Vegas",
        providerName: "Pragmatic Play",
      },
      SantasWonderland: {
        redirectUrl: "/casino/pp-santas-wonderland",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Santa'sWonderland.jpg",
        name: "Santa's Wonderland",
        providerName: "Pragmatic Play",
      },
      SmugglersCove: {
        redirectUrl: "/casino/pp-smugglers-cove",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SmugglersCove.jpg",
        name: "Smugglers Cove",
        providerName: "Pragmatic Play",
      },
      SpartanKing: {
        redirectUrl: "/casino/pp-spartan-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SpartanKing.jpg",
        name: "Spartan King",
        providerName: "Pragmatic Play",
      },
      StarBounty: {
        redirectUrl: "/casino/pp-star-bounty",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarBounty.jpg",
        name: "Star Bounty",
        providerName: "Pragmatic Play",
      },
      StarlightPrincess: {
        redirectUrl: "/casino/pp-starlight-princess",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarlightPrincess.jpg",
        name: "Starlight Princess",
        providerName: "Pragmatic Play",
      },
      StarPiratesCode: {
        redirectUrl: "/casino/pp-star-pirates-code",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarPiratesCode.jpg",
        name: "Star Pirates Code",
        providerName: "Pragmatic Play",
      },
      StarzMegaways: {
        redirectUrl: "/casino/pp-starz-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarzMegaways.jpg",
        name: "Starz Megaways",
        providerName: "Pragmatic Play",
      },
    },
    slide19: {
      StreetRacer: {
        redirectUrl: "/casino/pp-street-racer",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StreetRacer.jpg",
        name: "Street Racer",
        providerName: "Pragmatic Play",
      },
      SuperX: {
        redirectUrl: "/casino/pp-super-x",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SuperX.jpg",
        name: "Super X",
        providerName: "Pragmatic Play",
      },
      TemujinTreasures: {
        redirectUrl: "/casino/pp-temujin-treasures",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TemujinTreasures.jpg",
        name: "Temujin Treasures",
        providerName: "Pragmatic Play",
      },
      TheDogHouseMegaways: {
        redirectUrl: "/casino/pp-the-dog-house-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheDogHouseMegaways.jpg",
        name: "The Dog House Megaways",
        providerName: "Pragmatic Play",
      },
      TheDragonTiger: {
        redirectUrl: "/casino/pp-the-dragon-tiger",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheDragonTiger.jpg",
        name: "The Dragon Tiger",
        providerName: "Pragmatic Play",
      },
      TheHandofMidas: {
        redirectUrl: "/casino/pp-the-hand-of-midas",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheHandofMidas.jpg",
        name: "The Hand of Midas",
        providerName: "Pragmatic Play",
      },
      TheMagicCauldron: {
        redirectUrl: "/casino/pp-the-magic-cauldron",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheMagicCauldron.jpg",
        name: "The Magic Cauldron",
        providerName: "Pragmatic Play",
      },
      TheTigerWarrior: {
        redirectUrl: "/casino/pp-the-tiger-warrior",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheTigerWarrior.jpg",
        name: "The Tiger Warrior",
        providerName: "Pragmatic Play",
      },
      TheTweetyHouse: {
        redirectUrl: "/casino/pp-the-tweety-house",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheTweetyHouse.jpg",
        name: "The Tweety House",
        providerName: "Pragmatic Play",
      },
      TheWildMachine: {
        redirectUrl: "/casino/pp-the-wild-machine",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheWildMachine.jpg",
        name: "The Wild Machine",
        providerName: "Pragmatic Play",
      },
      ThreeStarFortune: {
        redirectUrl: "/casino/pp-three-star-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ThreeStarFortune.jpg",
        name: "Three Star Fortune",
        providerName: "Pragmatic Play",
      },
      TreasureWild: {
        redirectUrl: "/casino/pp-treasure-wild",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TreasureWild.jpg",
        name: "Treasure Wild",
        providerName: "Pragmatic Play",
      },
    },
    slide20: {
      UltraBurn: {
        redirectUrl: "/casino/pp-ultra-burn",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/UltraBurn.jpg",
        name: "Ultra Burn",
        providerName: "Pragmatic Play",
      },
      UltraHoldandSpin: {
        redirectUrl: "/casino/pp-ultra-hold-and-spin",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/UltraHoldandSpin.jpg",
        name: "Ultra Hold and Spin",
        providerName: "Pragmatic Play",
      },
      VoodooMagic: {
        redirectUrl: "/casino/pp-voodoo-magic",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/VoodooMagic.jpg",
        name: "Voodoo Magic",
        providerName: "Pragmatic Play",
      },
      WildBooster: {
        redirectUrl: "/casino/pp-wild-booster",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildBooster.jpg",
        name: "Wild Booster",
        providerName: "Pragmatic Play",
      },
      WildDepths: {
        redirectUrl: "/casino/pp-wild-depths",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildDepths.jpg",
        name: "Wild Depths",
        providerName: "Pragmatic Play",
      },
      WildWalker: {
        redirectUrl: "/casino/pp-wild-walker",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildWalker.jpg",
        name: "Wild Walker",
        providerName: "Pragmatic Play",
      },
      WildWestGold: {
        redirectUrl: "/casino/pp-wild-west-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildWestGold.jpg",
        name: "Wild West Gold",
        providerName: "Pragmatic Play",
      },
      WildWildRiches: {
        redirectUrl: "/casino/pp-wild-wild-riches",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildWildRiches.jpg",
        name: "Wild Wild Riches",
        providerName: "Pragmatic Play",
      },
      YumYumPowerways: {
        redirectUrl: "/casino/pp-yum-yum-powerways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/YumYumPowerways.jpg",
        name: "Yum Yum Powerways",
        providerName: "Pragmatic Play",
      },
    },
    name: "Pragmatic Play",
  },
  // PragmaticPlay: {
  //   slide1: {
  //     AndarBaharPragamatic: {
  //       redirectUrl: "/casino/ezugi/CrazyTime",
  //       code: "1024",
  //       casino: "ezugi",
  //       provider: "",
  //       homeUrl: pageUrlGenerator("/Casino"),
  //       imgUrl: "./images/pragmatic/AndarBahar.png",
  //       alt: "",
  //       name: "Andar Bahar",
  //       providerName: "Pragmatic Play",
  //     },
  //   },
  //   name: "PagmaticPlay",
  // },
  TvShows: {
    slide1: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      // GonzosTreasureHunt: {
      //   redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
      //   code: "1100022",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
      //   alt: "",
      //   name: "Gonzo's Treasure Hunt",
      //   providerName: "Evolution",
      // },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "TV Games",
  },
  AllGames: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
    },
    slide3: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide4: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    slide5: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      // TurkceBlackjack: {
      //   redirectUrl: "/casino/ezugi/TurkceBlackjack",
      //   code: "501",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
      //   alt: "",
      //   name: "Turkce Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      // FreebetBlackjack: {
      //   redirectUrl: "/casino/ezugi/FreebetBlackjack",
      //   code: "1000066",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
      //   alt: "",
      //   name: "Freebet Blackjack",
      //   providerName: "Evolution",
      // },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide6: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      // ClubBlackjack: {
      //   redirectUrl: "/casino/ezugi/ClubBlackjack",
      //   code: "502",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Club-Blackjack.jpg",
      //   alt: "",
      //   name: "Club Blackjack",
      //   providerName: "Ezugi",
      // },
      RumbaBlackjack2: {
        redirectUrl: "/casino/ezugi/RumbaBlackjack2",
        code: "412",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
        alt: "",
        name: "Rumba Blackjack 2",
        providerName: "Ezugi",
      },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      AllStarsBlackjackGold: {
        redirectUrl: "/casino/ezugi/AllStarsBlackjackGold",
        code: "504",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
        alt: "",
        name: "All Stars Blackjack",
        providerName: "Ezugi",
      },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide7: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti",
        code: "227102",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide8: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/aviator.gif",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },
    slide9: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      // GonzosTreasureHunt: {
      //   redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
      //   code: "1100022",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
      //   alt: "",
      //   name: "Gonzo's Treasure Hunt",
      //   providerName: "Evolution",
      // },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "All Games",
  },
  // thirtytwoCardtab: {
  //   thirtytwoCards: {
  //     redirectUrl: "/casino/ezugi/thirtytwoCards",
  //     code: "228002",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/card/32cards01.png",
  //     alt: "",
  //   },
  // },
  // sevenLuckytab: {
  //   sevenLucky: {
  //     redirectUrl: "/casino/ezugi/sevenLucky",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/lucky7/Lucky011.png",
  //     alt: "",
  //   },
  // },
  // sicbotab: {
  //   sicbodice01: {
  //     redirectUrl: "/casino/ezugi/sicbodice01",
  //     code: "224100",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice01.png",
  //     alt: "",
  //   },
  //   sicbodice02: {
  //     redirectUrl: "/casino/ezugi/sicbodice02",
  //     code: "224100",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice02.png",
  //     alt: "",
  //   },
  //   sicbodice03: {
  //     redirectUrl: "/casino/ezugi/sicbodice03",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice03.png",
  //     alt: "",
  //   },
  //   sicbodice04: {
  //     redirectUrl: "/casino/ezugi/sicbodice04",
  //     code: "224100",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice04.jpeg",
  //     alt: "",
  //   },
  //   sicbodice05: {
  //     redirectUrl: "/casino/ezugi/sicbodice05",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice05.png",
  //     alt: "",
  //   },
  // },
  // spribe: {
  //   aviator: {
  //     redirectUrl: "/casino/spribe/aviator",
  //     code: "aviator",
  //     casino: "spribe",
  //     provider: "aviator",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/aviator.gif",
  //     alt: "",
  //     name: "Aviator",
  //   },
  //   mines: {
  //     redirectUrl: "/casino/spribe/mines",
  //     code: "mines",
  //     casino: "spribe",
  //     provider: "mines",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Mines.jpg",
  //     alt: "",
  //     name: "Mines",
  //   },
  //   goal: {
  //     redirectUrl: "/casino/spribe/goal",
  //     code: "goal",
  //     casino: "spribe",
  //     provider: "goal",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Goal.jpg",
  //     alt: "",
  //     name: "Goal",
  //   },
  //   dice: {
  //     redirectUrl: "/casino/spribe/dice",
  //     code: "dice",
  //     casino: "spribe",
  //     provider: "dice",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Dice.jpg",
  //     alt: "",
  //     name: "Dice",
  //   },

  //   plinko: {
  //     redirectUrl: "/casino/spribe/plinko",
  //     code: "plinko",
  //     casino: "spribe",
  //     provider: "plinko",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Plinko.jpg",
  //     alt: "",
  //     name: "Plinko",
  //   },
  //   miniroulette: {
  //     redirectUrl: "/casino/spribe/miniroulette",
  //     code: "mini-roulette",
  //     casino: "spribe",
  //     provider: "mini-roulette",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Mini-roulette.jpg",
  //     alt: "",
  //     name: "Mini Roulette",
  //   },
  //   hilo: {
  //     redirectUrl: "/casino/spribe/hilo",
  //     code: "hi-lo",
  //     casino: "spribe",
  //     provider: "hi-lo",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/HIlo.jpg",
  //     alt: "",
  //     name: "Hilo",
  //   },
  // },

  // Slots: {
  //   twinspin: {
  //     href: "/casino/ezugi/twinspin",
  //     code: "1100039",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
  //   },
  //   gonzoquest: {
  //     href: "/casino/ezugi/gonzoquest",
  //     code: "1100018",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
  //   },

  //   asgardianstones: {
  //     href: "/casino/ezugi/asgardianstones",
  //     code: "1100096",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
  //   },
  //   secrets: {
  //     href: "/casino/ezugi/secrets",
  //     code: "1100106",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/secrets.jpg", alt: "" },
  //   },
  //   grandwheel: {
  //     href: "/casino/ezugi/grandwheel",
  //     code: "1100150",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
  //   },
  //   Strike777: {
  //     href: "/casino/ezugi/Strike777",
  //     code: "1100031",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Nights1000: {
  //     href: "/casino/ezugi/Nights1000",
  //     code: "1100003",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CashUltimate: {
  //     href: "/casino/ezugi/CashUltimate",
  //     code: "1100070",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CashVolt: {
  //     href: "/casino/ezugi/CashVolt",
  //     code: "1100071",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DazzleMegaways: {
  //     href: "/casino/ezugi/DazzleMegaways",
  //     code: "1100083",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DivinieFortuneMegaways: {
  //     href: "/casino/ezugi/DivinieFortuneMegaways",
  //     code: "1100089",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Dynamiteriches: {
  //     href: "/casino/ezugi/Dynamiteriches",
  //     code: "1100073",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FortuneHouse: {
  //     href: "/casino/ezugi/FortuneHouse",
  //     code: "1100007",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitShopMegaways: {
  //     href: "/casino/ezugi/FruitShopMegaways",
  //     code: "1100094",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenCryptex: {
  //     href: "/casino/ezugi/GoldenCryptex",
  //     code: "1100147",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenTsar: {
  //     href: "/casino/ezugi/GoldenTsar",
  //     code: "1100149",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GorillaKingdom: {
  //     href: "/casino/ezugi/GorillaKingdom",
  //     code: "1100088",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MystryReels: {
  //     href: "/casino/ezugi/MystryReels",
  //     code: "1100061",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RageoftheSea: {
  //     href: "/casino/ezugi/RageoftheSea",
  //     code: "1100116",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelKingMegaways: {
  //     href: "/casino/ezugi/ReelKingMegaways",
  //     code: "1100052",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TurnFortune: {
  //     href: "/casino/ezugi/TurnFortune",
  //     code: "1100041",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TwinSpinMegaways: {
  //     href: "/casino/ezugi/TwinSpinMegaways",
  //     code: "1100039",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WillysChills: {
  //     href: "/casino/ezugi/WillysChills",
  //     code: "1100128",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   VikingsSlots: {
  //     href: "/casino/ezugi/VikingsSlots",
  //     code: "1100124",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },

  //   ChineseTreasures: {
  //     href: "/casino/ezugi/ChineseTreasures",
  //     code: "1100006",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonLuck: {
  //     href: "/casino/ezugi/DragonLuck",
  //     code: "1100001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonFire: {
  //     href: "/casino/ezugi/DragonFire",
  //     code: "1100008",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonLuckDeluxe: {
  //     href: "/casino/ezugi/DragonLuckDeluxe",
  //     code: "1100011",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonLuckReel: {
  //     href: "/casino/ezugi/DragonLuckReel",
  //     code: "1100002",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   EpicJourney: {
  //     href: "/casino/ezugi/EpicJourney",
  //     code: "1100012",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GodWealth: {
  //     href: "/casino/ezugi/GodWealth",
  //     code: "1100005",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyFortune: {
  //     href: "/casino/ezugi/LuckyFortune",
  //     code: "1100010",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MagicGate: {
  //     href: "/casino/ezugi/MagicGate",
  //     code: "1100009",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenTemple: {
  //     href: "/casino/ezugi/GoldenTemple",
  //     code: "1100013",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PhoenixPower: {
  //     href: "/casino/ezugi/PhoenixPower",
  //     code: "1100014",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PiratesPlenty: {
  //     href: "/casino/ezugi/PiratesPlenty",
  //     code: "1100015",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenOffer: {
  //     href: "/casino/ezugi/GoldenOffer",
  //     code: "1100016",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RAsLegend: {
  //     href: "/casino/ezugi/RAsLegend",
  //     code: "1100017",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GonzoMegawaya: {
  //     href: "/casino/ezugi/GonzoMegawaya",
  //     code: "1100018                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RainbowJAcket: {
  //     href: "/casino/ezugi/RainbowJAcket",
  //     code: "1100019                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaPyramid: {
  //     href: "/casino/ezugi/MegaPyramid",
  //     code: "1100020                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Ozzy_Osbourne: {
  //     href: "/casino/ezugi/Ozzy_Osbourne",
  //     code: "1100024                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Jimi_Hendrix: {
  //     href: "/casino/ezugi/Jimi_Hendrix",
  //     code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Jimi_Hendrix: {
  //     href: "/casino/ezugi/Jimi_Hendrix",
  //     code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Jumanji: {
  //     href: "/casino/ezugi/Jumanji",
  //     code: "1100027                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MotorheadVideoSlot: {
  //     href: "/casino/ezugi/MotorheadVideoSlot",
  //     code: "1100028                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Starburst: {
  //     href: "/casino/ezugi/Starburst",
  //     code: "1100029                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WarofGod: {
  //     href: "/casino/ezugi/WarofGod",
  //     code: "1100030                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Christmas: {
  //     href: "/casino/ezugi/Christmas",
  //     code: "1100032                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ClusterPays: {
  //     href: "/casino/ezugi/ClusterPays",
  //     code: "1100033                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AncientsBlessing: {
  //     href: "/casino/ezugi/AncientsBlessing",
  //     code: "1100034                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AurumCodex: {
  //     href: "/casino/ezugi/AurumCodex",
  //     code: "1100035                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GunsNRoses: {
  //     href: "/casino/ezugi/GunsNRoses",
  //     code: "1100036                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BorisandBoo: {
  //     href: "/casino/ezugi/BorisandBoo",
  //     code: "1100037                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildWest: {
  //     href: "/casino/ezugi/WildWest",
  //     code: "1100038                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SteamTower: {
  //     href: "/casino/ezugi/SteamTower",
  //     code: "1100043                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ZeusLightning: {
  //     href: "/casino/ezugi/ZeusLightning",
  //     code: "1100044                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WingsofRa: {
  //     href: "/casino/ezugi/WingsofRa",
  //     code: "1100045                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Trillionaire: {
  //     href: "/casino/ezugi/Trillionaire",
  //     code: "1100047                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TreasureMine: {
  //     href: "/casino/ezugi/TreasureMine",
  //     code: "1100048                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ThorVengeance: {
  //     href: "/casino/ezugi/ThorVengeance",
  //     code: "1100049                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RegalStreak: {
  //     href: "/casino/ezugi/RegalStreak",
  //     code: "1100050                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RegalBeasts: {
  //     href: "/casino/ezugi/RegalBeasts",
  //     code: "1100051                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelKeeper: {
  //     href: "/casino/ezugi/ReelKeeper",
  //     code: "1100053                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RainbowJackpotsPowerLines: {
  //     href: "/casino/ezugi/RainbowJackpotsPowerLines",
  //     code: "1100055                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PiggyRichesMegaways: {
  //     href: "/casino/ezugi/PiggyRichesMegaways",
  //     code: "1100059                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JingleBells: {
  //     href: "/casino/ezugi/JingleBells",
  //     code: "1100065                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackinaPot: {
  //     href: "/casino/ezugi/JackinaPot",
  //     code: "1100066                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FiveStar: {
  //     href: "/casino/ezugi/FiveStar",
  //     code: "1100068                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BountyRaid: {
  //     href: "/casino/ezugi/BountyRaid",
  //     code: "1100069                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MysteryReels2: {
  //     href: "/casino/ezugi/MysteryReels2",
  //     code: "1100061                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DiamondBlitz: {
  //     href: "/casino/ezugi/DiamondBlitz",
  //     code: "1100072                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonFireMegaways: {
  //     href: "/casino/ezugi/DragonFireMegaways",
  //     code: "1100074                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Rome: {
  //     href: "/casino/ezugi/Rome",
  //     code: "1100075                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelRush: {
  //     href: "/casino/ezugi/ReelRush",
  //     code: "1100076                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BloodSuckers: {
  //     href: "/casino/ezugi/BloodSuckers",
  //     code: "1100077                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Pyramid: {
  //     href: "/casino/ezugi/Pyramid",
  //     code: "1100078                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ButterflyStaxx: {
  //     href: "/casino/ezugi/ButterflyStaxx",
  //     code: "1100079                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PiggyRiches: {
  //     href: "/casino/ezugi/PiggyRiches",
  //     code: "1100080                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DarkKing: {
  //     href: "/casino/ezugi/DarkKing",
  //     code: "1100081                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JingleSpin: {
  //     href: "/casino/ezugi/JingleSpin",
  //     code: "1100082                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Dazzle: {
  //     href: "/casino/ezugi/Dazzle",
  //     code: "1100083                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackHammer: {
  //     href: "/casino/ezugi/JackHammer",
  //     code: "1100084                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DeadorAlive: {
  //     href: "/casino/ezugi/DeadorAlive",
  //     code: "1100085                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackandtheBeanstalk: {
  //     href: "/casino/ezugi/JackandtheBeanstalk",
  //     code: "1100086                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DeadorAlive2: {
  //     href: "/casino/ezugi/DeadorAlive2",
  //     code: "1100087                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DivineFortuneMegaways: {
  //     href: "/casino/ezugi/DivineFortuneMegaways",
  //     code: "1100089                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GordonRamsayHellsKitchen: {
  //     href: "/casino/ezugi/GordonRamsayHellsKitchen",
  //     code: "1100090                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Elements: {
  //     href: "/casino/ezugi/Elements",
  //     code: "1100091                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GonzosQuest: {
  //     href: "/casino/ezugi/GonzosQuest",
  //     code: "1100092                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FinnandtheSwirlySpin: {
  //     href: "/casino/ezugi/FinnandtheSwirlySpin",
  //     code: "1100093                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitShop: {
  //     href: "/casino/ezugi/FruitShop",
  //     code: "1100095                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BloodSuckers2: {
  //     href: "/casino/ezugi/BloodSuckers2",
  //     code: "1100097                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ButterflyStaxx2: {
  //     href: "/casino/ezugi/ButterflyStaxx2",
  //     code: "1100098                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DazzleMeMegaways: {
  //     href: "/casino/ezugi/DazzleMeMegaways",
  //     code: "1100099                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DeadorAlive2FeatureBuy: {
  //     href: "/casino/ezugi/DeadorAlive2FeatureBuy",
  //     code: "1100100                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DoubleStacks: {
  //     href: "/casino/ezugi/DoubleStacks",
  //     code: "1100101                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FairytaleLegends: {
  //     href: "/casino/ezugi/FairytaleLegends",
  //     code: "1100102                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Flowers: {
  //     href: "/casino/ezugi/Flowers",
  //     code: "1100103                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitShopChristmasEdition: {
  //     href: "/casino/ezugi/FruitShopChristmasEdition",
  //     code: "1100104                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Hotline: {
  //     href: "/casino/ezugi/Hotline",
  //     code: "1100107                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Hotline2: {
  //     href: "/casino/ezugi/Hotline2",
  //     code: "1100108                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TheInvisibleMan: {
  //     href: "/casino/ezugi/TheInvisibleMan",
  //     code: "1100109                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   IrishPotLuck: {
  //     href: "/casino/ezugi/IrishPotLuck",
  //     code: "1100110                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackHammer2: {
  //     href: "/casino/ezugi/JackHammer2",
  //     code: "1100111                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitSpin: {
  //     href: "/casino/ezugi/FruitSpin",
  //     code: "1100112                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JungleSpirit: {
  //     href: "/casino/ezugi/JungleSpirit",
  //     code: "1100113                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   KoiPrincess: {
  //     href: "/casino/ezugi/KoiPrincess",
  //     code: "1100114                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DriveMultiplierMayhem: {
  //     href: "/casino/ezugi/DriveMultiplierMayhem",
  //     code: "1100115                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RageoftheSeas: {
  //     href: "/casino/ezugi/RageoftheSeas",
  //     code: "1100116                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RichesofMidgard: {
  //     href: "/casino/ezugi/RichesofMidgard",
  //     code: "1100117                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ScruffyDuck: {
  //     href: "/casino/ezugi/ScruffyDuck",
  //     code: "1100118                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SecretoftheStones: {
  //     href: "/casino/ezugi/SecretoftheStones",
  //     code: "1100119                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SecretsofChristmas: {
  //     href: "/casino/ezugi/SecretsofChristmas",
  //     code: "1100120                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SerengetiKings: {
  //     href: "/casino/ezugi/SerengetiKings",
  //     code: "1100121                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SpaceWars: {
  //     href: "/casino/ezugi/SpaceWars",
  //     code: "1100122                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Victorious: {
  //     href: "/casino/ezugi/Victorious",
  //     code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Warlords: {
  //     href: "/casino/ezugi/Warlords",
  //     code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildWater: {
  //     href: "/casino/ezugi/WildWater",
  //     code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildWorlds: {
  //     href: "/casino/ezugi/WildWorlds",
  //     code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Squad4: {
  //     href: "/casino/ezugi/Squad4",
  //     code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Families5: {
  //     href: "/casino/ezugi/Families5",
  //     code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AgentRoyale: {
  //     href: "/casino/ezugi/AgentRoyale",
  //     code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ArcadeBomb: {
  //     href: "/casino/ezugi/ArcadeBomb",
  //     code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Atlantis: {
  //     href: "/casino/ezugi/Atlantis",
  //     code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AztecSpins: {
  //     href: "/casino/ezugi/AztecSpins",
  //     code: "1100134                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CinderellaBall: {
  //     href: "/casino/ezugi/CinderellaBall",
  //     code: "1100135                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CirquedelaFortune: {
  //     href: "/casino/ezugi/CirquedelaFortune",
  //     code: "1100136                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ClashoftheBeasts: {
  //     href: "/casino/ezugi/ClashoftheBeasts",
  //     code: "1100137                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CrazyGenie: {
  //     href: "/casino/ezugi/CrazyGenie",
  //     code: "1100138                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CrystalMirror: {
  //     href: "/casino/ezugi/CrystalMirror",
  //     code: "1100139                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DaVinciMystery: {
  //     href: "/casino/ezugi/DaVinciMystery",
  //     code: "1100140                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DevilNumber: {
  //     href: "/casino/ezugi/DevilNumber",
  //     code: "1100141                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DiceDice: {
  //     href: "/casino/ezugi/DiceDice",
  //     code: "1100142                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FiveStarsPowerReels: {
  //     href: "/casino/ezugi/FiveStarsPowerReels",
  //     code: "1100144                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GemsGoneWild: {
  //     href: "/casino/ezugi/GemsGoneWild",
  //     code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GemsGoneWildPowerReels: {
  //     href: "/casino/ezugi/GemsGoneWildPowerReels",
  //     code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenLeprechaunMegaways: {
  //     href: "/casino/ezugi/GoldenLeprechaunMegaways",
  //     code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   HoardofPoseidon: {
  //     href: "/casino/ezugi/HoardofPoseidon",
  //     code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JewelScarabs: {
  //     href: "/casino/ezugi/JewelScarabs",
  //     code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JingleBellsPowerReels: {
  //     href: "/casino/ezugi/JingleBellsPowerReels",
  //     code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LegendofAthena: {
  //     href: "/casino/ezugi/LegendofAthena",
  //     code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyHalloween: {
  //     href: "/casino/ezugi/LuckyHalloween",
  //     code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyLittleDevil: {
  //     href: "/casino/ezugi/LuckyLittleDevil",
  //     code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyValentine: {
  //     href: "/casino/ezugi/LuckyValentine",
  //     code: "1100157                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyWizard: {
  //     href: "/casino/ezugi/LuckyWizard",
  //     code: "1100158                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Masquerade: {
  //     href: "/casino/ezugi/Masquerade",
  //     code: "1100159                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MayanGods: {
  //     href: "/casino/ezugi/MayanGods",
  //     code: "1100160                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaDragon: {
  //     href: "/casino/ezugi/MegaDragon",
  //     code: "1100161                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaJade: {
  //     href: "/casino/ezugi/MegaJade",
  //     code: "1100162                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaRise: {
  //     href: "/casino/ezugi/MegaRise",
  //     code: "1100163                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MultiplierRiches: {
  //     href: "/casino/ezugi/MultiplierRiches",
  //     code: "1100164                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MysteryReelsMegaways: {
  //     href: "/casino/ezugi/MysteryReelsMegaways",
  //     code: "1100165                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   NinjaWays: {
  //     href: "/casino/ezugi/NinjaWays",
  //     code: "1100166                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PathofDestiny: {
  //     href: "/casino/ezugi/PathofDestiny",
  //     code: "1100167                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PersianFortune: {
  //     href: "/casino/ezugi/PersianFortune",
  //     code: "1100168                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelHeist: {
  //     href: "/casino/ezugi/ReelHeist",
  //     code: "1100169                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RobinHoodWildForest: {
  //     href: "/casino/ezugi/RobinHoodWildForest",
  //     code: "1100170                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SnowWild: {
  //     href: "/casino/ezugi/SnowWild",
  //     code: "1100171                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SylvanSpirits: {
  //     href: "/casino/ezugi/SylvanSpirits",
  //     code: "1100172                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TheGreatestTrainRobbery: {
  //     href: "/casino/ezugi/TheGreatestTrainRobbery",
  //     code: "1100173                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TheWildHatter: {
  //     href: "/casino/ezugi/TheWildHatter",
  //     code: "1100174                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ThorLightning: {
  //     href: "/casino/ezugi/ThorLightning",
  //     code: "1100175                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TikiFruits: {
  //     href: "/casino/ezugi/TikiFruits",
  //     code: "1100176                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TotemLightning: {
  //     href: "/casino/ezugi/TotemLightning",
  //     code: "1100177                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   VaultCracker: {
  //     href: "/casino/ezugi/VaultCracker",
  //     code: "1100178                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   VaultofAnubis: {
  //     href: "/casino/ezugi/VaultofAnubis",
  //     code: "1100179                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WellofWishes: {
  //     href: "/casino/ezugi/WellofWishes",
  //     code: "1100180                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildCatsMultiline: {
  //     href: "/casino/ezugi/WildCatsMultiline",
  //     code: "1100181                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildOClock: {
  //     href: "/casino/ezugi/WildOClock",
  //     code: "1100182                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildChest: {
  //     href: "/casino/ezugi/WildChest",
  //     code: "1100183                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WinEscalator: {
  //     href: "/casino/ezugi/WinEscalator",
  //     code: "1100184                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   YucatanMystery: {
  //     href: "/casino/ezugi/YucatanMystery",
  //     code: "1100185                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  // },
};
